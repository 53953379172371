div.navBarCollapsed #navigationSideBar {
    display: none
}
div.navBarCollapsed #mainContentContainer {
    margin-left: 0;
    margin-top: 65px;
}
div.navBarCollapsed.isMobile #mainContentContainer {
    margin-left: 0;
}
#mainContentContainer {
    margin-top: 20px;
}
div.navBarCollapsed #navBarLogo {
    display: none
}

#navigationSideBar {
    width: 7em;
    position: fixed;
    color: black;
    z-index: 1;
    top: 0;
    left: 0;
    /*background-color: #111;*/
    padding: 10px;
    height: 100%;
    border-right: solid 1px #CCC;
}

.ellipsis {
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.navigationSideBarLink {
    color: black;
    display: block;
    margin-top: 10px;
}

button.navigationSideBarLink {
    color: black;
    display: block;
    margin-top: 10px;
}

#mainContentContainer {
    margin-left: 190px; /* Same as nav side bar width */
    padding: 0 20px 20px 20px;
}

div.isMobile #mainContentContainer {
    margin-left: 58px;
    padding: 0;
}

.navBarList .MuiListItem-gutters {
    padding-left: 24px;
    padding-right: 24px;
}
.navBarList .MuiListItemIcon-root {
    min-width: 48px;
}