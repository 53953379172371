.flex-wrap-row {
    display: flex;
    flex-wrap: wrap;
}
.flex-wrap-col-3 {
    flex-grow: 1;
    width: 25%;
    padding: 20px;
}
.textAlignCenter {
    text-align: center;
}