body {
  background-color: #f5f5f5;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.clearBoth {
  clear: both;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#loginFormContainer {
  width: 30em;
  margin: 200px auto;
}

#loginFormLogoContainer {
  text-align: center;
}

.loginFormErrorMessage {
  text-align: center;
}

h2 {
  font-size: 1.5em
}

h1, h2, h3, h4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

footer {
  margin-top: 40px;
  bottom: 0;
  margin-bottom: 40px;
}

a.secondaryLink {
  text-decoration: none;
  color: #888888;
}

.secondaryText {
  color: #888888;
}

a.secondaryLink:hover {
  text-decoration: underline;
}

a {
  /*text-decoration: none;*/
  color: #555555;
}